*,
body {
  scroll-behavior: smooth
}

/* Adiciona a fonte DM Serif Display com peso 400 */
@font-face {
  font-family: 'DM Serif Display';
  src: url('../assets/fonts/DMSerifDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

/* Adiciona a fonte Montserrat com pesos 400-500 */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}